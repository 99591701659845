<template>
  <div>
    <v-sheet>
      <v-card>
        <v-card-title>Турнирная таблица</v-card-title>
      </v-card>
      <v-simple-table class="mе-4 mb-4 ml-0 mr-0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Место</th>
              <th></th>
              <th class="text-left">Игрок</th>
              <th class="text-center">Баллы</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in displayItems"
              :key="item.id"
              style="cursor: pointer"
              @click="onUserProfileClick(item)"
            >
              <td
                class="text-center"
                :class="{ 'text-h5': item.index <= highlight }"
                style="width: 50px"
              >
                {{ item.index }}
              </td>
              <td style="width: 50px">
                <v-avatar class="ma-2" color="#38b6b4">
                  <v-img
                    v-if="item.thumbnail"
                    :src="item.thumbnail"
                    :alt="item.name"
                  />
                  <span v-else class="white--text text-h6">{{
                    item.initials
                  }}</span>
                </v-avatar>
              </td>
              <td
                class="text-left"
                style="text-transform: capitalize"
                :class="{ 'text-h5': item.index <= highlight }"
              >
                {{ item.name }}
                <template v-if="item.pvp === false"
                  ><v-icon color="primary" class="ml-2"
                    >mdi-shield-account</v-icon
                  ></template
                >
                <template v-else>
                  <v-icon color="primary" class="ml-2">mdi-dice-5</v-icon>
                </template>
              </td>
              <td
                class="text-center"
                :class="{ 'text-h5': item.index <= highlight }"
              >
                {{ item.points }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
    <v-dialog
      v-model="userDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="$vuetify.breakpoint.mobile ? null : 400"
    >
      <v-card v-if="selectedUser">
        <v-img v-if="selectedUser.image" :src="selectedUser.image"></v-img>
        <v-card-title>
          {{ selectedUser.name }}
          <v-icon color="primary" class="ml-2" v-if="selectedUser.pvp === false"
            >mdi-shield-account</v-icon
          >
        </v-card-title>
        <v-card-text>
          <div>Набранно баллов: {{ selectedUser.points }}</div>
          <div v-if="selectedUser.about" class="mt-2">
            О себе:
            <br />
            {{ selectedUser.about }}
          </div>
          <div v-if="selectedUser.birthCity" class="mt-2">
            Город рождения:
            <br />
            {{ selectedUser.birthCity }}
          </div>
          <div v-if="selectedUser.interestingFact" class="mt-2">
            Интересный факт:
            <br />
            {{ selectedUser.interestingFact }}
          </div>
          <v-alert
            type="primary"
            class="mt-2 mb-0"
            dense
            v-if="selectedUser.pvp === false && userId != selectedUser.userId"
          >
            Пользователь не принимает вызовы
          </v-alert>
          <v-alert
            type="primary"
            class="mt-2 mb-0"
            dense
            v-else-if="myPvPIsEnabled && pvpPointsDifference > minPvPDistance"
          >
            Разница баллов между соперниками должна быть не больше
            {{ minPvPDistance }}
          </v-alert>
        </v-card-text>
        <v-card-actions class="pb-4 pt-0 mt-0">
          <v-btn
            color="primary"
            v-if="
              myPvPIsEnabled &&
              pvpPointsDifference <= minPvPDistance &&
              !(selectedUser.pvp === false) &&
              selectedUser.userId !== userId
            "
            @click="onSendChallenge(selectedUser)"
            >Отправить вызов</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="userDialog = false">
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapMutations } from "vuex";

const getInitials = (name) => {
  if (typeof name !== "string") {
    return null;
  }
  if (name === "") return "";
  return name
    .split(" ")
    .filter((item) => item !== "")
    .map((item) => `${item[0]}`.toUpperCase())
    .reduce((acc, item) => {
      if (acc.length >= 2) return acc;
      return `${acc}${item}`;
    }, "");
};

export default {
  name: "Advent2023Standings",
  props: ["items", "my-points", "my-game-id", "params"],
  computed: {
    ...mapGetters({
      meta: "event/eventMeta",
      user: "event/user",
    }),
    userId() {
      return _.get(this.user, "id");
    },
    myPvPIsEnabled() {
      return _.get(this.myStandingsItem, "pvp", true);
    },
    myTotalPoints() {
      return _.get(this.myStandingsItem, "points", 0);
    },
    myStandingsItem() {
      return _.find(this.displayItems, (item) => item.userId === this.userId);
    },
    itemsWithName() {
      return _.filter(this.items, (item) => !!item.name).map((item) => {
        const questPoints = item.points;
        const profilePoints = item.image ? 19 : 0;
        const challengePoints = item.challengeBalance
          ? item.challengeBalance
          : 0;
        return {
          ...item,
          questPoints,
          profilePoints,
          challengePoints,
          points: questPoints + profilePoints + challengePoints,
        };
      });
    },
    sortedItems() {
      return _.orderBy(
        this.itemsWithName,
        ["points", "name", "user"],
        ["desc", "asc", "asc"]
      );
    },
    displayItems() {
      return this.sortedItems.map((item, index) => {
        return {
          ...item,
          index: index + 1,
          initials: getInitials(item.name),
        };
      });
    },
    highlight() {
      return _.get(this.params, "standings.highlight") || 0;
    },
    pvpPointsDifference() {
      if (this.selectedUser == null) return null;
      return Math.abs(this.myTotalPoints - this.selectedUser.points);
    },
  },
  data() {
    return {
      selectedUser: null,
      userDialog: false,
      minPvPDistance: 10,
    };
  },
  methods: {
    ...mapMutations({
      openChallengeDialog: "event/challenge/openChallengeDialog",
    }),
    onUserProfileClick(user) {
      this.selectedUser = user;
      this.userDialog = true;
    },
    onSendChallenge(user) {
      const usr = {
        id: user.userId,
        name: user.name,
        thumbnail: user.thumbnail,
        image: user.image,
      };
      this.openChallengeDialog(usr);
      this.userDialog = false;
      console.log("onSendChallenge", usr);
    },
  },
};
</script>
