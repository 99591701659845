<template>
  <div>
    <app-event :event-id="eventId" auth="login-only" :mapping="mapping">
      <template v-slot:title>
        <h2
          style="
            color: #000;
            font-variant-numeric: lining-nums proportional-nums;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          "
        >
          {{ title }}
        </h2>
      </template>
      <template v-slot:left-menu>
        <v-navigation-drawer
          app
          :value="drawer || !$vuetify.breakpoint.mobile"
          @input="setDrawer"
          dark
          class="primary"
        >
          <menu class="gb-menu">
            <a
              class="gb-menu__item"
              v-for="(item, index) in visibleItems"
              :key="index"
              @click.stop.prevent="onItemSelect(item.id)"
              href="#"
              :title="item.title"
              :style="{
                backgroundImage: getImageUrl(item),
              }"
              @mouseover="onMenuItemOver($event, item)"
              @mouseout="onMenuItemOut($event, item)"
            ></a>
          </menu>
        </v-navigation-drawer>
      </template>
    </app-event>
  </div>
</template>

<script>
import AppEvent from "@/components/event/Event.vue";
import eventMenuMixin from "../../mixins/eventMenuMixin";
import themeMixin from "@/mixins/eventTheme";
import Quest from "./Quest";
import Puzzles from "./Puzzles.vue";
import Shop from "./Shop/Index.vue";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import _ from "lodash";

export default {
  name: "AppGlowByte2024",
  components: {
    AppEvent,
  },
  mixins: [eventMenuMixin, themeMixin],
  data() {
    return {
      name: "",
      updating: false,
      mapping: {
        quest: Quest,
        puzzles: Puzzles,
        shop: Shop,
      },
    };
  },
  created() {
    console.log({ eventId: this.eventId });
    this.name = this.userName;
  },
  watch: {
    userName() {
      this.name = this.userName;
    },
  },
  computed: {
    ...mapGetters({
      user: "event/user",
    }),
    ...mapState(["drawer"]),
    profile() {
      return _.get(this.user, "profile");
    },
    userName() {
      return _.get(this.profile, "name");
    },
    eventId() {
      return this.$route.meta.eventId || "glowbyte-2024";
    },
  },
  methods: {
    ...mapActions({
      updateMyProfile: "event/updateMyProfile",
    }),
    ...mapMutations({
      setDrawer: "drawer",
    }),
    getImageUrl(item, isHov = false) {
      return `url(
                  'https://cdn.bahinsky.ru/glowbyte-2024/menu-items/menu-${
                    item.id === "" ? "calendar" : item.id
                  }${
        item.id === this.selectedItem.id || isHov ? "-hov" : ""
      }.png'
                )`;
    },
    onMenuItemOver(event, item) {
      event.target.style.backgroundImage = this.getImageUrl(item, true);
    },
    onMenuItemOut(event, item) {
      event.target.style.backgroundImage = this.getImageUrl(item);
    },
    async onSaveUserName() {
      const update = { name: this.name };
      this.updating = true;
      await this.updateMyProfile(update);
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Montserrat");

$font-family: "Montserrat";

.v-application {
  [class*="text-"] {
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
}

.gb-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 136px 50px 50px 35px;
  border: 0;
  width: 256px;
  min-height: 100%;
  background-color: #248c89;
  background: url("https://cdn.bahinsky.ru/glowbyte-2024/menu-items/menu-header.png"),
    linear-gradient(180deg, #38b6b4, #248c89);
  background-position: top left;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.gb-menu__item {
  display: block;
  margin: 0;
  padding: 0;
  width: 168px;
  height: 43px;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
